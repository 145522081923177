import { FC } from 'react'
import { MediaCard } from '@magal/components'
import { LinkFieldType, MediaFieldType } from '@magal/models'
import { useRouter } from 'next/router'

interface MediaCardPanelItemProps {
  card?: {
    title?: string
    subtitle?: string
    link?: LinkFieldType
    link_fr?: LinkFieldType
    link_de?: LinkFieldType
    image?: MediaFieldType
  }
  index?: number
}

export const MediaCardPanel: FC<MediaCardPanelItemProps> = ({
  card,
  index,
}) => {
  const { locale } = useRouter()

  const linkByLocale = (links: any) => {
    if (locale?.includes('fr') && links.link_fr) return links.link_fr
    if (locale?.includes('de') && links.link_de) return links.link_de

    return links.link
  }

  return (
    <MediaCard
      {...card}
      href={linkByLocale(card)?.href}
      key={index}
      imageSizes={
        '(min-width: 1000px) 26vw, (min-width: 740px) 34vw, (min-width: 430px) 52vw, 80vw'
      }
    />
  )
}
