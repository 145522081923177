import { Dispatch, FC, SetStateAction, useRef, useState } from 'react'
import { styled, useMediaQuery } from '@magal/styles'
import { SectionCardsSliderProps } from '@magal/models'
import { Button, Container, SectionWrap } from '@magal/components'
import { InView } from 'react-intersection-observer'
import { MediaCardPanel } from './Panel'
import { ChevronLeft, ChevronRight } from '@magal/icons'

const Title = styled(Container, {
  color: '$green_09',
  variants: {
    onlyMobile: {
      true: {
        '@lg': {
          display: 'none',
        },
      },
    },
  },
})

const Heading = styled('h2', {
  projectFont: 'caps01',
  marginBottom: '$40',
  color: '$green_09',
  maxWidth: '$maxWidthS',
})

const Slider = styled('div', {
  position: 'relative',
  display: 'grid',
  gridAutoFlow: 'column',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  width: '100%',
  gap: '$6',
  color: '$green_09',
  gridAutoColumns: '80vw',
  '@sm': {
    gridAutoColumns: '60vw',
  },
  '@md': {
    gridAutoColumns: '36vw',
  },
  '@lg': {
    gridAutoColumns: '25vw',
  },
  variants: {
    variant: {
      grid: {
        '@lg': {
          gridAutoColumns: '25vw',
          gridAutoFlow: 'inherit',
          gridTemplateColumns: 'repeat(4,1fr)',
          scrollSnapType: 'inherit',
        },
      },
      slider: {},
    },
  },
})

const ContentContainer = styled('div', {
  position: 'relative',
})

const ButtonsContainer = styled('div', {
  content: '',
  position: 'absolute',
  zIndex: '10',
  display: 'grid',
  width: '100%',
  height: '100%',
  padding: '$16',
  gridTemplateColumns: '1fr 1fr',
  pointerEvents: 'none',
})

const StyledButton = styled(Button, {
  position: 'relative',
  width: '$40',
  height: '$40',
  display: 'grid',
  placeContent: 'center',
  backgroundColor: '$white',
  borderRadius: '$rMax',
  transition: '400ms',
  pointerEvents: 'auto',
  '&:disabled': {
    opacity: 0,
  },
  '&:first-child': {
    placeSelf: 'center start',
  },
  '&:last-child': {
    placeSelf: 'center end',
  },
  '&:hover': {
    opacity: 0.8,
  },
  '&:active': {
    opacity: 0.6,
  },
})

function isSlideIntersecting(
  entry: IntersectionObserverEntry,
  slide: Element,
  buttonDisable: Dispatch<SetStateAction<boolean>>,
) {
  if (entry.target === slide) {
    buttonDisable(entry.isIntersecting)
  }
}
export const SectionCardsSlider: FC<SectionCardsSliderProps> = ({
  title,
  variant,
  cards,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const [isFirstSlideVisible, setIsFirstSlideVisible] = useState(true)
  const [isLastSlideVisible, setIsLastSlideVisible] = useState(false)
  const observerRef = useRef<null | IntersectionObserver>(null)
  const matchesMd = useMediaQuery('md')

  const scrollBySlides = (numberOfSlides: number) => {
    if (sliderRef.current) {
      const slides = sliderRef.current.childNodes as NodeListOf<Element>
      const slideWidth = slides[0].getBoundingClientRect().width
      const gutterWidth =
        slides[1].getBoundingClientRect().left -
        slides[0].getBoundingClientRect().right
      const destination =
        numberOfSlides * slideWidth + numberOfSlides * gutterWidth
      sliderRef.current?.scrollBy({
        left: destination,
        behavior: 'smooth',
      })
    }

    const firstSlideRef = sliderRef.current?.firstChild as Element
    const lastSlideRef = sliderRef.current?.lastChild as Element

    if (observerRef.current) observerRef.current.disconnect()

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          isSlideIntersecting(entry, firstSlideRef, setIsFirstSlideVisible)
          isSlideIntersecting(entry, lastSlideRef, setIsLastSlideVisible)
        })
      },
      {
        threshold: 0.9,
        rootMargin: '100% 0% 100% 0%',
      },
    )

    observerRef.current?.observe(firstSlideRef)
    observerRef.current?.observe(lastSlideRef)
  }

  return (
    <SectionWrap>
      {title && (
        <Title onlyMobile={false}>
          <Heading>{title}</Heading>
        </Title>
      )}
      <ContentContainer>
        {matchesMd && variant === 'slider' && (
          <ButtonsContainer>
            <StyledButton
              onClick={() => scrollBySlides(-1)}
              disabled={isFirstSlideVisible}
            >
              <ChevronLeft />
              <span className="sr-only">prev</span>
            </StyledButton>
            <StyledButton
              onClick={() => scrollBySlides(1)}
              disabled={isLastSlideVisible}
            >
              <ChevronRight />
              <span className="sr-only">next</span>
            </StyledButton>
          </ButtonsContainer>
        )}
        <InView triggerOnce={true} threshold={0.5}>
          <Slider variant={variant} ref={sliderRef}>
            {cards?.map((card, index) => (
              <MediaCardPanel
                card={card}
                index={index}
                key={`mediaCardPanel-${index}`}
              />
            ))}
          </Slider>
        </InView>
      </ContentContainer>
    </SectionWrap>
  )
}
